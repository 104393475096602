import React from "react";

const ConvinLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="250"
      height="60"
      viewBox="0 0 1632 510"
      fill="none">
      <path
        d="M606.367 229.915L636.713 194.007C618.758 174.788 590.183 162.65 563.378 162.65C508.504 162.65 468.044 200.834 468.044 252.421C468.044 304.767 507.745 343.71 561.608 343.71C588.413 343.71 617.494 330.308 636.713 309.572L606.367 277.203C594.229 289.594 577.792 297.686 564.137 297.686C540.114 297.686 521.906 277.962 521.906 251.916C521.906 225.869 540.114 206.398 564.137 206.398C578.804 206.398 595.241 215.754 606.367 229.915Z"
        fill="#333333"
      />
      <path
        d="M746.635 162.65C691.002 162.65 650.036 200.582 650.036 252.674C650.036 305.02 691.002 343.71 746.635 343.71C802.268 343.71 843.235 304.767 843.235 252.674C843.235 200.834 802.268 162.65 746.635 162.65ZM747.394 206.651C770.659 206.651 789.372 226.122 789.372 252.927C789.372 279.732 770.659 299.709 747.394 299.709C724.129 299.709 703.899 279.732 703.899 252.927C703.899 226.122 723.876 206.651 747.394 206.651Z"
        fill="#333333"
      />
      <path
        d="M873.013 164.167V341.434H921.313V242.812L996.417 341.434H1041.18V164.167H993.13V263.295L918.025 164.167H873.013Z"
        fill="#333333"
      />
      <path
        d="M1065.5 164.167L1133.28 341.434H1187.9L1256.43 164.167H1202.56L1162.1 286.813L1121.64 164.167H1065.5Z"
        fill="#333333"
      />
      <path
        d="M1280.46 164.167V341.434H1333.06V164.167H1280.46Z"
        fill="#333333"
      />
      <path
        d="M1373.06 164.167V341.434H1421.36V242.812L1496.46 341.434H1541.22V164.167H1493.18V263.295L1418.07 164.167H1373.06Z"
        fill="#333333"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M272.579 192.685C261.602 178.553 247.129 167.624 230.674 160.923C227.311 159.554 223.866 158.361 220.351 157.353C199.635 151.414 177.566 152.218 157.337 159.648C137.107 167.078 119.765 180.749 107.817 198.685C95.8698 216.621 89.9363 237.892 90.8746 259.423C91.8128 280.953 99.5744 301.627 113.037 318.456C126.499 335.284 144.965 347.395 165.765 353.037C186.564 358.679 208.618 357.56 228.74 349.842C232.154 348.532 235.482 347.044 238.713 345.387C254.51 337.286 267.969 325.152 277.674 310.135C277.681 310.123 277.689 310.112 277.696 310.1L230.76 279.795C230.757 279.8 230.755 279.804 230.752 279.808C227.012 285.596 222.033 290.432 216.216 293.992C213.85 295.439 211.346 296.675 208.73 297.679C199.675 301.152 189.751 301.656 180.391 299.117C171.032 296.578 162.722 291.128 156.664 283.555C150.606 275.982 147.113 266.679 146.691 256.99C146.268 247.301 148.939 237.73 154.315 229.658C159.691 221.587 167.495 215.435 176.599 212.092C185.702 208.748 195.633 208.386 204.955 211.059C207.649 211.831 210.251 212.845 212.733 214.081C218.841 217.122 224.223 221.509 228.453 226.952C228.454 226.954 228.456 226.956 228.458 226.958L272.579 192.685Z"
        fill="#6699FF"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M293.939 305.79C321.989 305.79 344.729 283.051 344.729 255C344.729 226.949 321.989 204.21 293.939 204.21C265.888 204.21 243.149 226.949 243.149 255C243.149 283.051 265.888 305.79 293.939 305.79ZM293.939 356.58C350.04 356.58 395.519 311.101 395.519 255C395.519 198.899 350.04 153.42 293.939 153.42C237.837 153.42 192.358 198.899 192.358 255C192.358 311.101 237.837 356.58 293.939 356.58Z"
        fill="#5FE6EB"
      />
    </svg>
  );
};

export default ConvinLogo;
